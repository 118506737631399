import type { Gender } from '../patient'
import type { ProviderType } from '../prescriber'
import type { EntityId } from '../shared'

export interface DirectTransferInput {
  directTransferId: string
  prescriptionImageS3Url?: string
  legacyCustomerId: number
  status: string
  patient: DirectTransferPatientInput
  prescriber: DirectTransferPrescriberInput
  patient_token: string
  transfer_from: DirectTransferFromInput
  transfer_to: DirectTransferToInput
  prescription: DirectTransferPrescriptionInput
  prior_authorization?: PriorAuthorization
}

export type PriorAuthorization = {
  key?: string
  type: string
  status: string
  submission_date: string
  start_date?: string
  expiration_date?: string
}

//from direct transfer DirectTransfer api
export interface DirectTransferPrescriberInput {
  first_name: string
  last_name: string
  npi: string
  address: DirectTransferAddress
  prescriber_type?: ProviderType
}

export interface DirectTransferFromInput {
  name: string
  address: DirectTransferAddress
  id_number: string
  pharmacist: string
}

export interface DirectTransferToInput {
  name: string
  id_number: string
  pharmacist: string
}

export interface DirectTransferPrescriptionInput {
  medication_name: string
  prescribed_ndc: string
  days_supply: number
  quantity_written: string
  quantity_dispensed?: string
  medication_sig: string
  refills_left?: number
  refills_transferred?: number
  fills_left?: number
  fills_transferred?: number
  number?: string
  can_substitute: boolean
  written_date: string
  direct_transfer_url?: string
  last_fill_date?: string
  first_fill_date?: string
  icd10_code?: string
  notes?: string
  diagnosis?: string
  quantity_transferred?: string
  expiration_date?: string
}

export interface DirectTransferPatientInput {
  id?: number
  first_name: string
  last_name: string
  gender: Gender
  dob: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  country?: string
  zip?: string
  phone?: string
  email?: string
  language_preference?: string
  guardian?: string
  species?: string
  viewed_notice_of_privacy_practices?: boolean
  viewed_notice_of_privacy_practices_date?: string
  patient_token: string
}

export interface DirectTransferAddress {
  name?: string
  street1: string
  city: string
  state: string
  zip: string
  company?: string
  street2?: string
  phone?: string
  email?: string
}

export interface DirectTransfer {
  directTransferId: string
  prescriptionImageS3Url?: string
  customerId: EntityId
  locationId: EntityId
  status: string
  patient: DirectTransferPatient
  prescriber: DirectTransferPrescriber
  patientToken: string
  transferFrom: DirectTransferFrom
  transferTo: DirectTransferTo
  prescription: DirectTransferPrescription
  raw: DirectTransferInput
  priorAuthorization?: PriorAuthorization
}

export interface DirectTransferPrescriber {
  firstName: string
  lastName: string
  suffix?: string
  stateLicense?: string
  npi: string
  address: DirectTransferAddress
  prescriberType?: ProviderType
}

export interface DirectTransferFrom {
  name: string
  address: DirectTransferAddress
  idNumber: string
  pharmacist: string
}

export interface DirectTransferTo {
  name: string
  idNumber: string
  pharmacist: string
}

export interface DirectTransferPrescription {
  medicationName: string
  prescribedNdc: string
  daysSupply: number
  quantityWritten: string
  quantityDispensed?: string
  medicationSig: string
  refillsLeft: number
  refillsTransferred: number
  number: string
  canSubstitute: boolean
  writtenDate: string
  directTransferUrl?: string
  lastFillDate?: string
  firstFillDate?: string
  icd10Code?: string
  notes?: string
  diagnosis?: string
  quantityTransferred?: string
}

export interface DirectTransferPatient {
  firstName: string
  lastName: string
  gender: Gender
  dob: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  country?: string
  zip?: string
  phone?: string
  email?: string
  languagePreference?: string
  guardian?: string
  species?: string
  viewedNoticeOfPrivacyPractices?: boolean
  viewedNoticeOfPrivacyPracticesDate?: string
  patientToken: string
}

export enum DirectTransferStatus {
  NEW = 'NEW',
  REJECT = 'REJECT',
}
