import { useEffect } from 'react'
import { BannerAlert, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { CREATE_SIG_CODE } from 'gql'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import SigCodeForm from './SigCodeForm'

const AddSigCodeModal = (): JSX.Element => {
  return (
    <ModalWrapper styles={[{ width: '31rem', minHeight: '35rem' }]} id="addSigCodeModal">
      <ModalHeader>
        <ModalTitle>Add sig code</ModalTitle>
      </ModalHeader>
      <AddSigCodeModalContent />
    </ModalWrapper>
  )
}

const AddSigCodeModalContent = (): JSX.Element => {
  const [createSigCode, { data, loading, error }] = useMutation(CREATE_SIG_CODE, { refetchQueries: ['listSigCodes'] })
  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const {
    actions: { clearFormData },
  } = useFormData()

  useEffect(() => {
    clearFormData?.()
  }, [clearFormData])

  useEffect(() => {
    if (data) {
      showSuccessToast('Sig code created succesfully.')
      dismissModal()
    }
  }, [data, dismissModal, showSuccessToast])

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return (
    <>
      {error && (
        <BannerAlert state="error">
          <Text variant="body-sm">{error.message}</Text>
        </BannerAlert>
      )}
      <SigCodeForm
        buttonText="Add"
        onSubmit={(sigCode: string, englishDirections: string, spanishTranslation?: string) => {
          createSigCode({
            variables: {
              createSigCodeInput: {
                code: sigCode,
                enTranslation: englishDirections,
                esTranslation: spanishTranslation,
              },
            },
          })
        }}
      />
    </>
  )
}

const LoadingSpinnerContainer = styled.div`
  width: 10rem;
  margin: 0 auto;
`

export default AddSigCodeModal
