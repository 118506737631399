import type { ClaimData, Dispensed } from '@truepill/tpos-types'
import isPaidClaim from './isPaidClaim'

const doesDispensedMatchClaim = (claims: ClaimData[], dispensed: Dispensed): boolean => {
  const paidClaims = claims.filter(isPaidClaim)
  const doesDispensedMatchClaim = paidClaims.some(
    claim =>
      claim.sent.groups?.[0]?.claim?.productId === dispensed.ndc &&
      claim.sent.groups?.[0]?.claim?.daysSupply === dispensed.daysSupply &&
      claim.sent.groups?.[0]?.claim?.quantityDispensed === dispensed.quantity,
  )
  return doesDispensedMatchClaim
}

export default doesDispensedMatchClaim
