import type { ReactNode } from 'react'
import { Button } from '@truepill/react-capsule'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ModalTitle, InputContainer, ButtonsContainer } from 'components/Modal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { ChildProps } from 'types'

type ModalProps = {
  confirmationCallback?: () => void
  cancelCallback?: () => void
  title: string | ReactNode
  icon?: ReactNode
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  constrainWidth?: boolean
  hideCancel?: boolean
  width?: string
  contentMargin?: string
} & ChildProps

const SimpleModal = ({
  title,
  confirmButtonLabel = 'OK',
  cancelButtonLabel = 'Cancel',
  confirmationCallback,
  cancelCallback,
  constrainWidth = true,
  width,
  contentMargin,
  hideCancel,
  children,
  icon,
}: ModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()

  return (
    <ModalWrapper id="SimpleModal">
      <ModalHeader closeCallback={cancelCallback}>
        {icon && <IconWrapper>{icon} </IconWrapper>}
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <InputContainer width={constrainWidth ? width ?? '24rem' : undefined} margin={contentMargin}>
        {children}
      </InputContainer>
      <ButtonsContainer>
        {!hideCancel && (
          <Button
            variant="primary-text"
            disabled={false}
            onClick={() => {
              dismissModal()
              cancelCallback?.()
            }}
          >
            {cancelButtonLabel}
          </Button>
        )}
        <Button
          disabled={false}
          onClick={() => {
            dismissModal()
            confirmationCallback && confirmationCallback()
          }}
        >
          {confirmButtonLabel}
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

export default SimpleModal
