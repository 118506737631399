import type { ApolloClient, DocumentNode, InMemoryCache, SubscribeToMoreOptions } from '@truepill/tpos-react-router'
import { useSubscription } from '@truepill/tpos-react-router'
import {
  GET_CUSTOMER,
  GET_LOCATION,
  GET_FULL_ORDER,
  GET_PRESCRIPTION,
  SUBSCRIBE_CUSTOMER,
  SUBSCRIBE_PATIENT_MATCH,
  SUBSCRIBE_LOCATION,
  SUBSCRIBE_ORDER,
  SUBSCRIBE_PRESCRIPTION,
  SUBSCRIBE_PRINTER,
  SUBSCRIBE_USER,
  SUBSCRIBE_PATIENT,
  SUBSCRIBE_NOTES,
} from 'gql'
import type { Fill, Order, Prescription, RXFillRequest } from 'types'

export function replaceById(
  arr: Record<string, unknown>[],
  replacement: Record<string, unknown>,
): Record<string, unknown>[] {
  return arr.map(o => {
    if (o._id === replacement._id) {
      return { ...replacement }
    }
    return { ...o }
  })
}

type SubscribeActions = 'delete' | 'insert' | 'replace' | 'update'
type SubscribeToMoreFn = (options: SubscribeToMoreOptions) => () => void

export function actionResolver(
  action: SubscribeActions,
  entities: Record<string, unknown>[],
  replacement: Record<string, unknown>,
): Record<string, unknown>[] {
  if (!entities) {
    return []
  }
  if (['replace', 'update'].includes(action)) {
    return replaceById(entities, { ...replacement, isModified: true })
  } else if (action === 'insert') {
    return [...entities, { ...replacement, isNew: true }]
  } else if (action === 'delete') {
    return entities.map(o => {
      if (o._id === replacement._id) return { ...o, isDeleted: true }

      return { ...o }
    })
  }
  return []
}

export function updateLocation(subscribeToMore: SubscribeToMoreFn): void {
  subscribeToMore?.({
    document: SUBSCRIBE_LOCATION,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      if (!subscriptionData.data?.locationUpdate?.location || !prev?.getLocations) return prev

      return {
        ...prev,
        getLocations: actionResolver(
          subscriptionData.data?.locationUpdate?.operation,
          prev.getLocations,
          subscriptionData.data.locationUpdate?.location,
        ),
      }
    },
    onError: onSubscriptionError,
  })
}

export function updatePrinter(subscribeToMore: SubscribeToMoreFn): void {
  subscribeToMore?.({
    document: SUBSCRIBE_PRINTER,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      if (!subscriptionData.data?.printerUpdate?.printer || !prev?.getPrinters) return prev

      return {
        ...prev,
        getPrinters: actionResolver(
          subscriptionData.data?.printerUpdate?.operation,
          prev.getPrinters,
          subscriptionData.data.printerUpdate.printer,
        ),
      }
    },
    onError: onSubscriptionError,
  })
}

export function updateNotes(
  subscribeToMore: SubscribeToMoreFn,
  variables: {
    patientId?: string
    orderId?: string
    prescriptionId?: string
    prescriberId?: string
    fillId?: string
    payerId?: string
  },
): CleanupFunction {
  return subscribeToMore?.({
    document: SUBSCRIBE_NOTES,
    variables,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      return {
        ...prev,
        getAllLogs: actionResolver(
          subscriptionData.data?.logUpdate?.operation,
          prev.getAllLogs,
          subscriptionData.data.logUpdate.log,
        ),
      }
    },
    onError: onSubscriptionError,
  })
}

export function updatePatientMatch(subscribeToMore: SubscribeToMoreFn): CleanupFunction {
  return subscribeToMore?.({
    document: SUBSCRIBE_PATIENT_MATCH,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      if (!subscriptionData.data?.patientMatchUpdate?.patientMatch || !prev?.getPatientMatches) return prev

      const resolution = actionResolver(
        subscriptionData.data?.patientMatchUpdate?.operation,
        prev.getPatientMatches.patientMatches,
        subscriptionData.data?.patientMatchUpdate?.patientMatch,
      )
      return {
        ...prev,
        getPatientMatches: { ...prev?.getPatientMatches, patientMatches: resolution },
        patientMatches: resolution,
      }
    },
    onError: onSubscriptionError,
  })
}

export function updateUser(subscribeToMore: SubscribeToMoreFn): CleanupFunction {
  return subscribeToMore?.({
    document: SUBSCRIBE_USER,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      if (!subscriptionData.data?.userUpdate?.user || !prev?.getUsers) return prev

      return {
        ...prev,
        getUsers: actionResolver(
          subscriptionData.data?.userUpdate?.operation,
          prev.getUsers,
          subscriptionData.data.userUpdate?.user,
        ),
      }
    },
    onError: onSubscriptionError,
  })
}

export function updateCustomer(subscribeToMore: SubscribeToMoreFn): void {
  subscribeToMore?.({
    document: SUBSCRIBE_CUSTOMER,
    updateQuery: (prev: any, { subscriptionData }: any) => {
      if (!subscriptionData.data?.customerUpdate?.customer || !prev?.getCustomers) return prev

      return {
        ...prev,
        getCustomers: actionResolver(
          subscriptionData.data?.customerUpdate?.operation,
          prev.getCustomers,
          subscriptionData.data.customerUpdate.customer,
        ),
      }
    },
    onError: onSubscriptionError,
  })
}

type CleanupFunction = () => void

function idTracker(
  sourceArray: string[],
  ids: string[],
  subscribe: (newIds: string[]) => CleanupFunction,
  skipIdTracker = false,
): CleanupFunction {
  if (skipIdTracker) {
    return subscribe(ids)
  }

  for (const newId of ids) {
    if (!sourceArray.includes(newId)) {
      sourceArray.push(newId)
    }
  }

  subscribe(sourceArray)

  return function cleanup() {
    for (const cleanupId of ids) {
      const idx = sourceArray.indexOf(cleanupId)

      if (idx > -1) sourceArray.splice(idx, 1)
    }
  }
}

const orderIds: string[] = []

export function updateOrder(
  subscribeToMore: SubscribeToMoreFn,
  variables: { orderId?: string; orderIds?: string[] },
  skipIdTracker: boolean,
): CleanupFunction {
  if (!variables.orderId && !variables.orderIds?.length) throw new Error('You Must Supply an orderId or orderIds.')

  const tryNewIds = variables.orderId ? [variables.orderId] : [...(variables.orderIds as string[])]

  return idTracker(
    orderIds,
    tryNewIds,
    newIds => {
      return subscribeToMore?.({
        document: SUBSCRIBE_ORDER,
        variables: { orderIds: newIds },
        updateQuery: (prev: any, { subscriptionData }: any) => {
          if (!subscriptionData.data?.orderUpdate?.order) return prev
          const order = subscriptionData.data?.orderUpdate?.order
          if (variables.orderIds) {
            return {
              ...(prev.getOrders && {
                getOrders: {
                  ...prev.getOrders,
                  orders: actionResolver('replace', prev.getOrders, order),
                },
              }),
              ...(prev.getFulFillmentOrders && {
                getFulFillmentOrders: {
                  ...prev.getFulFillmentOrders,
                  orders: actionResolver('replace', prev.getFulFillmentOrders, order),
                },
              }),
            }
          } else {
            if (variables.orderId !== order._id || prev?.getOrder?._id !== order._id) return prev

            return { getOrder: order }
          }
        },
        onError: onSubscriptionError,
      })
    },
    skipIdTracker,
  )
}

function mapRxFillRequestsToPrescription(rxFillRequests: RXFillRequest[], prescription: Prescription) {
  return rxFillRequests.map(fr => {
    if (fr.prescriptionId === prescription._id) {
      return {
        ...fr,
        prescription,
        fill: prescription.fills.find((fill: Fill) => fill._id === fr.fillId),
      }
    }

    return fr
  })
}

const prescriptionIds: string[] = []

export function updatePrescription(
  subscribeToMore: SubscribeToMoreFn,
  variables: { prescriptionId: string },
): CleanupFunction {
  if (!variables.prescriptionId) throw new Error('You Must Supply prescriptionIds.')

  return idTracker(
    prescriptionIds,
    [variables.prescriptionId],
    newIds => {
      return subscribeToMore?.({
        document: SUBSCRIBE_PRESCRIPTION,
        variables: { prescriptionIds: newIds },
        updateQuery: (prev: any, { subscriptionData }: any) => {
          if (!subscriptionData.data?.prescriptionUpdate?.prescription) return prev
          const prescription = subscriptionData.data?.prescriptionUpdate?.prescription
          return { ...prev, ...prescription }
        },
        onError: onSubscriptionError,
      })
    },
    true,
  )
}

export function updatePrescriptionOnOrder(
  subscribeToMore: SubscribeToMoreFn,
  variables: { prescriptionIds: string[] },
  multiOrder = true,
  skipIdTracker: boolean,
): CleanupFunction {
  if (!variables.prescriptionIds?.length) throw new Error('You Must Supply prescriptionIds.')

  const tryNewIds = [...variables.prescriptionIds]

  return idTracker(
    prescriptionIds,
    tryNewIds,
    newIds => {
      return subscribeToMore?.({
        document: SUBSCRIBE_PRESCRIPTION,
        variables: { prescriptionIds: newIds },
        updateQuery: (prev: any, { subscriptionData }: any) => {
          if (!subscriptionData.data?.prescriptionUpdate?.prescription) return prev
          const prescription = subscriptionData.data?.prescriptionUpdate?.prescription

          if (multiOrder) {
            return {
              getOrders: {
                ...prev.getOrders,
                orders: prev.getOrders.orders.map((order: Order) => ({
                  ...order,
                  rxFillRequests: mapRxFillRequestsToPrescription(order.rxFillRequests, prescription),
                })),
              },
            }
          } else {
            return {
              getOrder: {
                ...prev.getOrder,
                rxFillRequests: mapRxFillRequestsToPrescription(prev.getOrder.rxFillRequests, prescription),
              },
            }
          }
        },
        onError: onSubscriptionError,
      })
    },
    skipIdTracker,
  )
}

const patientIds: string[] = []

export function updatePatient(
  subscribeToMore: SubscribeToMoreFn,
  variables: { patientId?: string; patientIds?: string[] },
  skipIdTracker: boolean,
): CleanupFunction {
  if (!variables.patientId && !variables.patientIds?.length)
    throw new Error('You Must Supply a patientId or patientId.')

  const tryNewIds = variables.patientId ? [variables.patientId] : [...(variables.patientIds as string[])]

  return idTracker(
    patientIds,
    tryNewIds,
    newIds => {
      return subscribeToMore?.({
        document: SUBSCRIBE_PATIENT,
        variables: { patientIds: newIds },
        onError: onSubscriptionError,
      })
    },
    skipIdTracker,
  )
}

type subscribeToIdType = {
  subscription: DocumentNode
  subscriptionVariables: any
  query: DocumentNode
  client: ApolloClient<InMemoryCache>
}

export function SubscribeToId({ subscription, subscriptionVariables, query, client }: subscribeToIdType): void {
  const { data, error } = useSubscription(subscription, { variables: subscriptionVariables })

  if (error) return console.error(error)

  if (data)
    client.writeQuery({
      query,
      variables: { _id: data._id },
      data,
    })
}

export function SubscribeToAll(client: ApolloClient<InMemoryCache>, csSupportOnly = false): void {
  const allSupport = [
    [SUBSCRIBE_CUSTOMER, GET_CUSTOMER],
    [SUBSCRIBE_LOCATION, GET_LOCATION],
    [SUBSCRIBE_ORDER, GET_FULL_ORDER, { csSupportOnly }],
  ]

  if (!csSupportOnly) {
    allSupport.concat([[SUBSCRIBE_PRESCRIPTION, GET_PRESCRIPTION]])
  }

  allSupport.forEach(innerArray =>
    SubscribeToId({
      subscription: innerArray[0] as DocumentNode,
      query: innerArray[1] as DocumentNode,
      subscriptionVariables: innerArray[2] as any,
      client,
    }),
  )
}

function onSubscriptionError(error: Error) {
  console.error(`Error subscribing to cache updates: ${error.message}`)
}
