import type { PrescriberAddress } from '@truepill/tpos-types'
import { ProviderType } from '@truepill/tpos-types'
import { isEmpty, sortBy } from 'lodash'
import type { Address, Prescriber } from 'types'
import { formatPhoneNumber, isCompleteAddress, validPhoneNumber } from 'utils'
import { formatAddress, validateAddressForm } from './address'

// NPI is a 10-position all-numeric identification number
export const validateNpi = (npi: string): boolean => !!npi && npi.length === 10 && /^\d+$/.test(npi)

export const validatePractice = (practiceAddress: Address, phoneNumber: string, faxNumber: string): boolean =>
  isCompleteAddress(practiceAddress) &&
  !!phoneNumber &&
  validPhoneNumber(formatPhoneNumber(phoneNumber)) &&
  (!faxNumber || validPhoneNumber(formatPhoneNumber(faxNumber)))

export const validatePrescriberAndPractice = (
  npi: string,
  stateLicense: string,
  firstName: string,
  lastName: string,
  suffix: string,
  practiceAddress: Address,
  phoneNumber: string,
  faxNumber: string,
): boolean =>
  ((suffix !== ProviderType.DVM && validateNpi(npi)) || (suffix === ProviderType.DVM && !!stateLicense)) &&
  !!firstName &&
  !!lastName &&
  !!suffix &&
  validatePractice(practiceAddress, phoneNumber, faxNumber)

export const isPrescriberFormComplete = (prescriber: Partial<Prescriber>): boolean => {
  if (!prescriber) {
    return false
  }

  // Prescriber ID means that a prescriber has been found so we can be sure the form has
  // been completed
  if (prescriber._id) {
    return true
  }

  return validatePrescriberAndPractice(
    prescriber.npi || '',
    prescriber.stateLicense || '',
    prescriber.firstName || '',
    prescriber.lastName || '',
    prescriber.suffix || '',
    prescriber.addresses?.[0] as Address,
    prescriber.addresses?.[0].phone || '',
    prescriber.addresses?.[0].fax || '',
  )
}

export const validatePrescriberForm = (
  prescriber: Partial<Prescriber>,
): { isValid: boolean; errors: Record<string, string> } => {
  if (!prescriber) {
    return { isValid: false, errors: {} }
  }

  // Prescriber ID means this is a found prescriber so we can be sure the form has
  // been completed
  if (prescriber._id) {
    return { isValid: true, errors: {} }
  }

  const { npi, firstName, lastName, suffix, stateLicense } = prescriber
  const errors: Record<string, string> = {}
  const requiredFields = {
    npi,
    firstName,
    lastName,
    suffix,
    stateLicense,
  }

  if (!validateNpi(npi || '')) {
    errors.npi = 'Must have 10 digits'
  }

  Object.entries(requiredFields).forEach(([key, value]) => {
    if (!value) {
      errors[key] = 'Missing field'
    }
  })

  return { isValid: isEmpty(errors), errors }
}

export const validatePrescriberPracticeForm = (
  address: PrescriberAddress | undefined,
): { isValid: boolean; errors: Record<string, string> } => {
  const errors: Record<string, string> = { ...validateAddressForm(address).errors }

  if (!address?.phone) {
    errors.phone = 'Missing field'
  } else if ((address.phone.match(/\d+/g) || []).join('').length < 10) {
    errors.phone = '10 digits minimum'
  }

  return { isValid: isEmpty(errors), errors }
}

export type AddressOption = PrescriberAddress & { title: string }

export const getOptionsFromAddresses = (addresses: PrescriberAddress[] | undefined): AddressOption[] => {
  return sortBy(
    addresses?.map(address => ({
      ...address,
      title: `${address.businessName || '-'}, ${formatAddress(address)}`,
    })) || [],
    ['city', 'street1'],
  )
}
