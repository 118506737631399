import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly, type SpeciesOptions } from '@truepill/tpos-types'
import dayjs from 'dayjs'
import { SEARCH_PATIENTS } from 'gql'
import { useFlag } from 'providers/LaunchDarklyProvider'
import type { Patient, PatientStatus } from 'types'
import {
  PATIENT_EMAIL_REGEXP,
  PATIENT_NAME_REGEXP,
  PATIENT_PHONE_REGEXP,
  PATIENT_TOKEN_REGEXP,
  PATIENT_ZIP_REGEXP,
} from 'utils'

const normalizeDOB = (dob: string): string | undefined => {
  //YYYYMMDD, MMDDYYYY, YYYY-MM-DD, MM/DD/YYYY, MM/DD/YY, MM-DD-YYYY, mm/dd/yyyy (no leading zeroes), mm/dd/yy, MM-DD-YYYY

  const possibleFormats = [
    'YYYYMMDD',
    'MMDDYYYY',
    'YYYY-MM-DD',
    'MM/DD/YYYY',
    'MM/DD/YY',
    'MM-DD-YYYY',
    'M-D-YYYY',
    'M-D-YY',
    'M/D/YYYY',
    'M/D/YY',
  ]

  if (dayjs(dob, possibleFormats, 'en', true).isValid()) {
    return dayjs(dob, possibleFormats, 'en', true).format('MM/DD/YYYY')
  }

  return undefined
}

/**
 * A hook to wrap use of the SEARCH_PATIENTS query, so that components using it
 * don't have to cross-reference or duplicate the processing logic for the
 * search term variables.
 *
 * The query is skipped if the search term is invalid, so you don't need to
 * specially preprocess it (like checking against blank values).
 *
 * @returns An array of Patients, or undefined if the search term is invalid or
 *          empty, the data is still loading, or there was an error.
 */
export default function useSearchPatients(
  searchTerm: string,
  status?: PatientStatus,
  species?: SpeciesOptions,
): QueryResult<
  { searchPatients: Patient[] },
  {
    token?: string
    email?: string
    phone?: string
    name?: string
    species?: SpeciesOptions
    dob?: string
    zip?: string
  }
> {
  const tempEnhancePatientSearch = useFlag(LaunchDarkly.FeatureFlags.TEMP_ENHANCE_PATIENT_SEARCH)

  let params = {}
  let returnConditions = false

  const cleanedSearchTerm = searchTerm.trim()

  let dobFormatted: string | undefined = undefined
  let name: string | undefined = undefined
  let nameSearch = cleanedSearchTerm.match(PATIENT_NAME_REGEXP)?.[0]

  const updatedSearchTerm = nameSearch ? cleanedSearchTerm.replace(nameSearch, '').trim() : undefined

  if (!nameSearch || !updatedSearchTerm || !tempEnhancePatientSearch) {
    dobFormatted = normalizeDOB(cleanedSearchTerm)

    nameSearch = cleanedSearchTerm.match(PATIENT_NAME_REGEXP)?.[0]
    name = nameSearch && nameSearch === cleanedSearchTerm ? nameSearch : undefined
  } else {
    dobFormatted = normalizeDOB(updatedSearchTerm)
    name = nameSearch && nameSearch.trim() ? nameSearch.trim() : undefined
  }

  const tokenSearch = cleanedSearchTerm.match(PATIENT_TOKEN_REGEXP)?.[0]
  const token = tokenSearch && tokenSearch === cleanedSearchTerm ? tokenSearch : undefined

  const emailSearch = cleanedSearchTerm.match(PATIENT_EMAIL_REGEXP)?.[0]
  const email = emailSearch && emailSearch === cleanedSearchTerm ? emailSearch : undefined

  const phoneSearch = cleanedSearchTerm.match(PATIENT_PHONE_REGEXP)?.[0]
  const phone = phoneSearch && phoneSearch === cleanedSearchTerm ? phoneSearch : undefined

  const zipSearch = cleanedSearchTerm.match(PATIENT_ZIP_REGEXP)?.[0]
  const zip = zipSearch && zipSearch === cleanedSearchTerm ? zipSearch : undefined

  params = {
    skip: !token && !email && !phone && !name && !dobFormatted && !zip,
    variables: {
      token,
      email,
      phone,
      name,
      species,
      dob: dobFormatted,
      zip,
      status,
    },
  }

  returnConditions = !token && !email && !phone && !name && !dobFormatted && !zip

  const { data, ...rest } = useQuery<{ searchPatients: Patient[] }>(SEARCH_PATIENTS, params)

  return {
    data: returnConditions ? undefined : data,
    ...rest,
  }
}
