import type { CSSProperties, KeyboardEvent } from 'react'
import { useRef, useContext, useEffect, useState, useCallback } from 'react'
import Drop from 'components/DropDownContainer'
import DropDownOption from 'components/DropDownOption'
import SearchInput from 'components/SearchInput'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useClickOutside from 'hooks/useClickOutside'
import { HotKeyLevel } from 'hooks/useHotKey'
import type { HotKeyProps } from 'providers/HID/HotKeyProvider'
import { HotKeyContext } from 'providers/HID/HotKeyProvider'
import styled from 'styled-components'
import { capsuleDarkGrayColor } from 'styles/styleVariables'
import type { OptChildProps } from 'types'
import useKeyPress from './useKeyPress'

interface SuggestionProps {
  currentValue: string
  allowCustomInput: boolean
  autoCompleteSuggestions: any[]
  highlightedIndex?: number
  suggestionComponent: (props: any) => JSX.Element
  containerWidth?: string
  showOptionsOnFocus?: boolean
  onSelect: (index: number) => void
  onCustomInputSelect?: (selectedSuggestion: any) => void
  preventScrolling: boolean
  onBlur?: () => void
  'data-testid'?: string
  maximumHeight?: string
}

// TODO: Move from anys to generics

export type SearchAutoCompleteProps = {
  css?: CSSProperties
  iconColor?: string
  allowCustomInput?: boolean
  placeholder?: string
  valueSelected?: boolean
  value: string
  onChange: (text: string) => void
  onSelect: (selectedSuggestion: any) => void
  onCustomInputSelect?: (selectedSuggestion: any) => void
  onBlur?: () => void
  hotKey?: string
  clearSearchTerm?: () => void
  autoCompleteSuggestions: any[]
  suggestionComponent?: (props: any) => JSX.Element
  useCustomSuggestionsWrapper?: boolean
  suggestionsWrapperComponent?: (props: any) => JSX.Element
  isModal?: boolean
  retainFocusAfterSelection?: boolean
  showOptionsOnFocus?: boolean
  preventScrolling?: boolean
  backspaceCallback?: () => void
  idKey?: string // used to disambiguate hotkey registrations
  containerWidth?: string
  'data-testid'?: string
  maximumHeight?: string
  withCapsule?: boolean
  label?: string
  disabled?: boolean
  state?: 'default' | 'complete' | 'error'
} & OptChildProps

const SearchAutoComplete = (props: SearchAutoCompleteProps): JSX.Element => {
  const {
    css,
    isModal = false,
    iconColor = '#000',
    autoCompleteSuggestions,
    suggestionComponent: SuggestionComponent,
    useCustomSuggestionsWrapper = false,
    suggestionsWrapperComponent: SuggestionsWrapperComponent,
    onChange,
    onSelect,
    onCustomInputSelect,
    withCapsule = false,
    onBlur,
    retainFocusAfterSelection = true,
    showOptionsOnFocus,
    backspaceCallback,
    value,
    allowCustomInput = false,
    clearSearchTerm = () => undefined,
    placeholder,
    idKey: key,
    preventScrolling = true,
    containerWidth,
    'data-testid': testId,
    maximumHeight,
    label,
  } = props
  const [showAutoComplete, setShowAutoComplete] = useState(false)
  const inputRef = useRef<HTMLSpanElement>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const { registerHotKey, deRegisterHotKey } = useContext(HotKeyContext) as HotKeyProps

  const idKey = key || placeholder

  const onBlurDeRegister = useCallback(() => {
    const level = isModal ? HotKeyLevel.modal : HotKeyLevel.normal
    deRegisterHotKey('Backspace', level, undefined, idKey)
  }, [isModal, deRegisterHotKey, idKey])

  const onBlurDeRegisterRef = useAutoUpdatingRef(onBlurDeRegister)

  useClickOutside(
    inputRef,
    () => {
      setShowAutoComplete(false)
      clearSearchTerm()
      setHighlightedIndex(-1)
      onBlurDeRegisterRef.current()
    },
    true,
  )

  const numEntries = useRef<number>(0)

  useEffect(() => {
    if (allowCustomInput && !value?.length) {
      setShowAutoComplete(false)
    }
  }, [value, allowCustomInput])

  useEffect(() => {
    numEntries.current = autoCompleteSuggestions?.length

    if ((autoCompleteSuggestions?.length && value.length) || (allowCustomInput && value?.length > 0)) {
      setShowAutoComplete(true)
    }
  }, [value, autoCompleteSuggestions, allowCustomInput])

  useEffect(() => {
    // De-register on unmount
    const currentOnBlurReRegister = onBlurDeRegisterRef.current
    return () => currentOnBlurReRegister && currentOnBlurReRegister()
  }, [onBlurDeRegisterRef])

  const moveUpList = useCallback(() => {
    setHighlightedIndex(Math.max(highlightedIndex - 1, 0))
  }, [highlightedIndex, setHighlightedIndex])

  const moveDownList = useCallback(() => {
    const val = Math.min(highlightedIndex + 1, autoCompleteSuggestions.length - 1)
    setHighlightedIndex(val)
  }, [highlightedIndex, setHighlightedIndex, autoCompleteSuggestions])

  const selectSuggestion = useCallback(
    (clickedIndex?: number) => {
      if (!retainFocusAfterSelection) {
        searchInputRef.current && searchInputRef.current.blur()
      }
      const index = clickedIndex ?? highlightedIndex
      const value = autoCompleteSuggestions[index]
      if (value && !value.loading) {
        onSelect(value)
        setShowAutoComplete(false)
        clearSearchTerm()
      }
    },
    [autoCompleteSuggestions, clearSearchTerm, highlightedIndex, onSelect, retainFocusAfterSelection],
  )

  // Sadly can't rely on useHotKey as this component will often load with
  // multiple copies on the same screen and we therefore need to re-implement
  // the reference handling here so we can hook into onBlur and onFocus
  const moveDownListRef = useRef(moveDownList)
  const moveUpListRef = useRef(moveUpList)
  useEffect(() => {
    moveDownListRef.current = moveDownList
  }, [moveDownList])
  useEffect(() => {
    moveUpListRef.current = moveUpList
  }, [moveUpList])

  const onBackspaceRef = useRef(backspaceCallback)
  useEffect(() => {
    onBackspaceRef.current = backspaceCallback
  }, [backspaceCallback])

  const onFocusRegister = useCallback(() => {
    const level = isModal ? HotKeyLevel.modal : HotKeyLevel.normal
    setHighlightedIndex(0)
    registerHotKey(
      'Backspace',
      level,
      () => onBackspaceRef.current && onBackspaceRef.current(),
      false,
      undefined,
      idKey,
    )
  }, [isModal, registerHotKey, setHighlightedIndex, idKey])

  useKeyPress('Tab', () => setShowAutoComplete(false))

  return (
    <AutoCompleteContainer
      data-testid={testId ? `${testId}-autocomplete-container` : 'autocomplete-container'}
      ref={inputRef}
      containerWidth={containerWidth}
    >
      <SearchInput
        css={css}
        iconColor={iconColor}
        label={label}
        withCapsule={withCapsule}
        {...props}
        searchInputRef={searchInputRef}
        onKeyDown={(ev: KeyboardEvent<HTMLInputElement>) => {
          if (ev.key === 'ArrowDown') {
            ev.preventDefault()
            moveDownListRef.current()
          } else if (ev.key === 'ArrowUp') {
            ev.preventDefault()
            moveUpListRef.current()
          } else if (ev.key === 'Enter') {
            selectSuggestion(highlightedIndex)
          }
        }}
        onFocus={() => {
          if (value.length || showOptionsOnFocus) {
            setShowAutoComplete(true)
          }
          onFocusRegister()
        }}
        onBlur={() => {
          onBlurDeRegisterRef.current && onBlurDeRegisterRef.current()
          onBlur && onBlur()
        }}
        onChange={({ currentTarget: { value } }) => {
          setHighlightedIndex(0)
          onChange(value)
        }}
      />
      {showAutoComplete && !useCustomSuggestionsWrapper && !!SuggestionComponent && (
        <Suggestions
          currentValue={value}
          allowCustomInput={allowCustomInput}
          onCustomInputSelect={onCustomInputSelect}
          data-testid={testId}
          autoCompleteSuggestions={autoCompleteSuggestions}
          highlightedIndex={highlightedIndex}
          suggestionComponent={SuggestionComponent}
          showOptionsOnFocus={showOptionsOnFocus}
          containerWidth={containerWidth}
          onSelect={selectSuggestion}
          onBlur={onBlur}
          preventScrolling={preventScrolling}
          maximumHeight={maximumHeight}
        />
      )}
      {showAutoComplete && useCustomSuggestionsWrapper && !!SuggestionsWrapperComponent && (
        <SuggestionsWrapperComponent
          data-testid={testId}
          autoCompleteSuggestions={autoCompleteSuggestions}
          highlightedIndex={highlightedIndex}
          suggestionComponent={SuggestionComponent}
          showOptionsOnFocus={showOptionsOnFocus}
          containerWidth={containerWidth}
          onSelect={selectSuggestion}
          onBlur={onBlur}
          preventScrolling={preventScrolling}
          maximumHeight={maximumHeight}
        />
      )}
    </AutoCompleteContainer>
  )
}

const Suggestions = ({
  currentValue,
  onCustomInputSelect,
  allowCustomInput,
  autoCompleteSuggestions,
  highlightedIndex,
  suggestionComponent: SuggestionComponent,
  containerWidth,
  showOptionsOnFocus,
  onSelect,
  preventScrolling,
  onBlur,
  'data-testid': testId,
  maximumHeight,
}: SuggestionProps): JSX.Element => {
  return (
    <Drop
      data-testid={testId ? `${testId}-suggestions` : 'suggestions'}
      forceDisplay={showOptionsOnFocus}
      preventScrolling={preventScrolling}
      maximumHeight={maximumHeight}
      containerWidth={containerWidth}
    >
      {autoCompleteSuggestions.map((suggestionProps: any, i: number) => (
        <SuggestionComponent
          data-testid="suggestion-item"
          key={i}
          {...{
            highlighted: i === highlightedIndex,
            onClick: () => {
              onSelect(i)
            },
            onBlur: () => {
              onBlur && onBlur()
            },
            ...suggestionProps,
          }}
        />
      ))}
      {allowCustomInput && (
        <DropDownOption
          data-testid="suggestion-item"
          highlighted={false}
          onClick={() => {
            onCustomInputSelect?.(currentValue)
          }}
        >
          <div>
            <CustomInputContainer>
              <strong>Custom input: </strong> <p>{currentValue}</p>
            </CustomInputContainer>
          </div>
        </DropDownOption>
      )}
    </Drop>
  )
}

const CustomInputContainer = styled.span`
  color: ${capsuleDarkGrayColor};
  display: flex;
  gap: 0.25rem;
  & strong {
    font-weight: 700;
  }
`

const AutoCompleteContainer = styled.span<{ containerWidth?: string }>`
  position: relative;
  ${props => (props.containerWidth ? `width: ${props.containerWidth}` : null)};
`

export default SearchAutoComplete
