import { useState, useEffect } from 'react'
import { Grid, GridItem } from '@truepill/react-capsule'
import { useLazyQuery, useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ActionButtonOutline } from 'components/ActionButton'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper, { LargeIconWrapper } from 'components/IconWrapper'
import { CapsulePageContainer, PageContent, CapsulePageHeading, CapsuleTitle } from 'components/PageStructure'
import Pagination from 'components/Pagination'
import type { TableDefinition } from 'components/Table'
import Table from 'components/Table'
import { LIST_SIG_CODES, REMOVE_SIG_CODE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { capsulePrimaryColorDark } from 'styles/styleVariables'
import type { SigCode } from 'types'
import AddSigCodeModal from './components/AddSigCodeModal'
import EditSigCodeModal from './components/EditSigCodeModal'

interface TableRow {
  _id: string
  code: string
  es?: string
  en: string
}

interface useListSigCodesQueryWithPaginationProps {
  pageSize: number
}

interface EditActionButtonProps {
  data: TableRow
}

const useListSigCodesQueryWithPagination = ({ pageSize }: useListSigCodesQueryWithPaginationProps) => {
  const [results, setResults] = useState<SigCode[]>([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(pageSize)
  const [totalResults, setTotalResults] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [listSigCodes, { data: sigCodes, loading, error }] = useLazyQuery(LIST_SIG_CODES)

  useEffect(() => {
    if (sigCodes) {
      const totalRecords = sigCodes?.listSigCodes?.totalRecords
      setTotalResults(totalRecords)
      setTotalPages(Math.ceil(totalRecords / limit))
      setResults(sigCodes?.listSigCodes?.records)
    }
  }, [sigCodes, limit])

  useEffect(() => {
    listSigCodes?.({ variables: { pageSize: limit, pageNumber: page } })
  }, [listSigCodes, limit, page])

  return { sigCodes: results, totalResults, totalPages, loading, error, setPage, setLimit, page, limit }
}

const parseSigCodeData = (data?: SigCode[]) => {
  return data
    ? data.map(({ code, translations, _id }) => {
        return {
          _id,
          code,
          es: translations.es,
          en: translations.en,
        }
      })
    : []
}

const RemoveActionButton = ({ data }: EditActionButtonProps) => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [removeSigCode] = useMutation(REMOVE_SIG_CODE, {
    refetchQueries: ['listSigCodes'],
    onError: () => {
      showErrorToast('An error ocurred while trying to remove the sig code.')
    },
    onCompleted: () => {
      showSuccessToast('Removed succesfully.')
    },
  })

  return (
    <LargeIconWrapper>
      <DeleteIcon
        style={{ cursor: 'pointer' }}
        fill={capsulePrimaryColorDark}
        onClick={() => {
          removeSigCode({
            variables: {
              id: data._id,
            },
          })
        }}
      />
    </LargeIconWrapper>
  )
}

const EditActionButton = ({ data }: EditActionButtonProps) => {
  const { showModal } = useModalContext()
  return (
    <LargeIconWrapper>
      <EditIcon
        style={{ cursor: 'pointer' }}
        fill={capsulePrimaryColorDark}
        onClick={() => {
          showModal(() => <EditSigCodeModal code={data.code} esText={data.es} enText={data.en} sigCodeId={data._id} />)
        }}
      />
    </LargeIconWrapper>
  )
}

const tableDataDefinition: TableDefinition<TableRow>[] = [
  { field: 'code', headerName: 'Sig code', width: '1fr', minWidth: '12rem' },
  { field: 'en', headerName: 'English directions', width: '2fr', minWidth: '30rem' },
  { field: 'es', headerName: 'Spanish translation', width: '2fr', minWidth: '30rem' },
  {
    headerName: 'Actions',
    minWidth: '4rem',
    width: '4rem',
    customRender: (data: TableRow) => (
      <Grid>
        <GridItem>
          <EditActionButton data={data} />
        </GridItem>
        <GridItem>
          <RemoveActionButton data={data} />
        </GridItem>
      </Grid>
    ),
  },
]

const SigCodesAdministration = (): JSX.Element => {
  const { showModal } = useModalContext()
  const {
    sigCodes,
    loading,
    setPage,
    setLimit,
    totalPages,
    totalResults,
    page: currentPage,
    limit,
  } = useListSigCodesQueryWithPagination({
    pageSize: 10,
  })

  return (
    <CapsulePageContainer>
      <BreadCrumb useCapsuleDesign />
      <CapsulePageHeading>
        <CapsuleTitle>Sig code library</CapsuleTitle>
        <div>
          <ActionButtonOutline
            icon={
              <IconWrapper>
                <PlusIcon />
              </IconWrapper>
            }
            hotKey="n"
            label="Add sig code"
            onClick={() => {
              showModal(() => <AddSigCodeModal />)
            }}
          />
        </div>
      </CapsulePageHeading>
      <PageContent>
        <TableWrapper>
          <Table
            rowStyle={{ alignItems: 'center' }}
            definition={tableDataDefinition}
            data={parseSigCodeData(sigCodes)}
            loading={loading}
            keyField="code"
          />
          <Pagination
            onChangePage={setPage}
            totalRecords={totalResults}
            currentPage={currentPage}
            totalPages={totalPages}
            pagesForEachSide={1}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50]}
            onChangeRowsPerPage={setLimit}
          />
        </TableWrapper>
      </PageContent>
    </CapsulePageContainer>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export default SigCodesAdministration
