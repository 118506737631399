import { useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_ORDER_TRIAGE_REASONS, SEARCH_COPAY_TRIAGE_REASONS } from 'gql'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import { borderColor } from 'styles/styleVariables'
import type { TriageReasonSearchResult } from 'types'
import { TriageReasonMap, CopayTriageReasonMap } from 'utils'

interface TriageReason {
  key: string
  value: string
}

interface TriageReasonSuggestion extends TriageReason {
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type TriageReasonSuggestionItem = TriageReasonSuggestion | Error | Loading

interface TriageSearchProps {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
  nameSpace?: string
  isCopay?: boolean
}

const TriageSearch = (props: TriageSearchProps): JSX.Element => {
  const { isVisible, hotKey = 'l', isModal, isCopay = false } = props
  const { currentLocation, routeToMergedQuery, tokenContext } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')
  const fulfillmentQueue = useFulfillmentQueue()

  const query = isCopay ? SEARCH_COPAY_TRIAGE_REASONS : SEARCH_ORDER_TRIAGE_REASONS

  const { data, loading, error } = useQuery(query, {
    variables: {
      locationId: currentLocation.queryMap?.locationId || tokenContext?.locationId,
      orderStages: fulfillmentQueue?.orderStages ?? [],
      fillStages: fulfillmentQueue?.fillStages ?? [],
    },
  })

  const triageMap = isCopay ? CopayTriageReasonMap : TriageReasonMap
  const searchTriageReasons = data?.searchOrderTriageReasons || data?.searchCopayTriageReasons || []
  const TriageReasonOptions: TriageReason[] = searchTriageReasons.map((searchResult: TriageReasonSearchResult) => {
    // if the reason is not stored as key, just store key as value as well
    return {
      key: searchResult._id,
      value: triageMap[searchResult._id] ? triageMap[searchResult._id] : searchResult._id,
    }
  })

  const selectedReasonKeys: string[] = currentLocation?.queryMap?.triageReasons || []
  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by reason....'}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Triage Reasons: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : TriageReasonOptions?.filter(c => c.value.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        value={searchTerm}
        showOptionsOnFocus={true}
        onSelect={(triageReason: TriageReason) => {
          const reasonKey = triageReason.key
          if (!selectedReasonKeys.includes(reasonKey))
            routeToMergedQuery({ triageReasons: [...selectedReasonKeys, reasonKey] })

          setSearchTerm('')
        }}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as TriageReasonSuggestionItem)} />}
        onChange={setSearchTerm}
        maximumHeight="16rem"
      />
      <SearchResultLozengeList>
        {selectedReasonKeys.map((reasonKey: any) => {
          return (
            <SearchResultLozenge
              key={reasonKey}
              closeCallback={() => {
                if (selectedReasonKeys.includes(reasonKey)) {
                  const newReasonKeys = [...selectedReasonKeys]
                  newReasonKeys.splice(selectedReasonKeys.indexOf(reasonKey), 1)

                  routeToMergedQuery({ triageReasons: newReasonKeys })
                }
              }}
            >
              <p>{triageMap[reasonKey] || reasonKey}</p>
            </SearchResultLozenge>
          )
        })}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: TriageReasonSuggestionItem) => {
  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Searching...</p>
      </DropDownOption>
    )
  }

  const triageReason = props as TriageReasonSuggestion

  return (
    <DropDownOption {...triageReason}>
      <p>{triageReason.value}</p>
    </DropDownOption>
  )
}

export default TriageSearch
