import { useCallback, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly, type StockInfo } from '@truepill/tpos-types'
import TaskList from 'components/TaskList'
import { CREATE_LOG, GET_NDC_FULL_INFO } from 'gql'
import gql from 'graphql-tag'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useBarcodeScanner, { REGEXES } from 'hooks/useBarcodeScanner'
import useDevToolBarcode from 'hooks/useDevtoolBarcode'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import LotAccuracyFillModal from 'modals/LotAccuracyFillModal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Task } from 'providers/TaskProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { usePlusClient } from 'providers/VisionRouter'
import type { Fill, NdcFullInfo, Order, Prescription } from 'types'
import { getNextRxFillRequest, parseGS1String, isScannedCodeAnAlternateBarCode } from 'utils'
import { validateNdcBarcode } from 'utils/ndc'
import { BarcodeTypes } from './useFirstScanType'

const CONFIRM_FILL_SCAN_WITH_STOCK_INFO = gql`
  mutation confirmFillScanWithStockInfo(
    $orderId: ID!
    $fillId: ID!
    $ndc: String!
    $stockInfo: [StockInfoInput!]!
    $manualEntryFilled: Boolean!
  ) {
    confirmFillScanWithStockInfo(
      orderId: $orderId
      fillId: $fillId
      ndcPackage: $ndc
      stockInfo: $stockInfo
      manualEntryFilled: $manualEntryFilled
    ) {
      fill {
        _id
        dispensed {
          ndc
          packagesRequired
          stockInfo {
            lot
            expirationDate
            serialNumber
            quantity
          }
          manualEntryFilled
        }
        shortFillCode
      }
    }
  }
`

// Function to format the expiry date
const formatExpirationDate = (expiryDate: string): string => {
  const [year, month, day] = expiryDate.match(/\d{2}/g) || []
  return `20${year}-${month}-${day}`
}

const useInitTasks = (fill: Fill, ndc: string): void => {
  const { dispensed, shortFillCode } = fill
  const { initTasks } = useTaskContext()
  const initTasksRef = useAutoUpdatingRef(initTasks)

  const { data } = useQuery(GET_NDC_FULL_INFO, {
    variables: { ndcs: [fill.dispensed.ndc] },
  })

  const ndcFullInfo: NdcFullInfo = data?.getNdcFullInfo[0]
  const packageInfo = ndcFullInfo?.packages.find(pkg => pkg.ndc === fill.dispensed.ndc)

  useEffect(() => {
    const initialScanFillLabel = 'Scan prescription bar code'
    const tasks: Task[] = [
      {
        label: initialScanFillLabel,
        key: 'scanFill',
        completed: false,
        code: shortFillCode,
      } as Task,
    ]

    // This part of the code already creates non-optional tasks depending on dispensed.packagesRequired
    // E.g. dispensed.packagesRequired = 2 is equivalent to 2 tasks to scan for unbreakable products
    const totalPackagesRequired = dispensed.packagesRequired || 1
    for (let index = 0; index < totalPackagesRequired; index++) {
      const label = `Scan stock product bar code (${index + 1} of ${totalPackagesRequired})`
      const showScanAdditionalStock = index + 1 === totalPackagesRequired && packageInfo && !packageInfo?.isUnbreakable

      tasks.push({
        key: `scanProduct-${index}`,
        label,
        completed: false,
        code: ndc,
        subtasks: showScanAdditionalStock ? [{ label: 'Scan additional stock product as needed' }] : [],
      } as Task)
    }

    // Second Rx Label scan task added to the end to complete the Fill
    const finalScanFillLabel = 'Scan prescription bar code to complete task'
    tasks.push({
      label: finalScanFillLabel,
      key: 'finalScanFill',
      completed: false,
      code: shortFillCode,
    } as Task)

    initTasksRef.current(tasks)
  }, [initTasksRef, dispensed, ndc, shortFillCode, fill, packageInfo])
}

const useSubmitOnComplete = (
  tasks: Task[],
  fill: Fill,
  order: Order,
  requireModalQuantityFilled: boolean,
  manualEntryFilled: boolean,
  stockInfo?: StockInfo[],
) => {
  const { initTasks } = useTaskContext()
  const showErrorToast = useErrorToast()
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const showSuccessToast = useSuccessToast(true)
  const showSuccessToastRef = useAutoUpdatingRef(showSuccessToast)
  const { routeTo, tokenContext } = usePlusClient()

  const fulfillmentQueueName = FulfillmentQueueName.Fill
  const [confirmFillScanWithStockInfo] = useMutation(CONFIRM_FILL_SCAN_WITH_STOCK_INFO)

  useEffect(() => {
    const handleSubmit = async () => {
      try {
        if (!order || order.inTriage) {
          return
        }

        initTasks([])

        await confirmFillScanWithStockInfo({
          variables: {
            orderId: order._id,
            fillId: fill._id,
            ndc: fill.dispensed.ndc,
            manualEntryFilled,
            stockInfo,
          },
        })

        showSuccessToastRef.current('Step complete')

        const { nextFill } = getNextRxFillRequest(order, fill, fulfillmentQueueName)
        const nextRoute = nextFill
          ? routeTo.fulfillment(order._id, nextFill.fill._id, fulfillmentQueueName)
          : routeTo.fulfillmentQueue(fulfillmentQueueName, {
              searchMap: {
                locationId: tokenContext.locationId,
              },
            })

        nextRoute.now()
      } catch (e) {
        showErrorToastRef.current('Failed to submit: ' + e.message)
        console.error(e)
      }
    }

    if (tasks.length >= 3 && tasks.every(({ completed }) => completed) && !requireModalQuantityFilled) {
      handleSubmit()
    }
  }, [
    tasks,
    order,
    confirmFillScanWithStockInfo,
    initTasks,
    showSuccessToastRef,
    showErrorToastRef,
    routeTo,
    fulfillmentQueueName,
    fill,
    stockInfo,
    tokenContext.locationId,
    requireModalQuantityFilled,
    manualEntryFilled,
  ])
}

type FillTaskListProps = {
  fill: Fill
  prescription: Prescription
  order: Order
  stockInfo: StockInfo[]
  setStockInfo: React.Dispatch<React.SetStateAction<StockInfo[]>>
  requireModalQuantityFilled: boolean
  alternateBarCodes: string[]
  allowLinearScan?: boolean
  firstScanType?: BarcodeTypes
  setFirstScanType: (type: BarcodeTypes) => void
  manualEntryFilled: boolean
}

const FillTaskList = ({
  fill,
  prescription,
  order,
  stockInfo,
  setStockInfo,
  requireModalQuantityFilled,
  alternateBarCodes,
  allowLinearScan,
  firstScanType,
  setFirstScanType,
  manualEntryFilled,
}: FillTaskListProps): JSX.Element => {
  const { tasks, completeTask, scanProductsTasksCompleted, nextProductScanTaskIndex } = useTaskContext()
  const showErrorToast = useErrorToast()
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const showSuccessToast = useSuccessToast(true)
  const showSuccessToastRef = useAutoUpdatingRef(showSuccessToast)
  const { registerListener, deregisterListener } = useBarcodeScanner()
  const { showModal } = useModalContext()
  const [createLog] = useMutation(CREATE_LOG, {
    refetchQueries: ['getAllLogs'],
  })

  const { data } = useQuery(GET_NDC_FULL_INFO, {
    variables: { ndcs: [fill.dispensed.ndc] },
  })

  const enableLotAccuracyModal = useFlag(LaunchDarkly.FeatureFlags.ENABLE_LOT_ACCURACY_MODAL_GTIN_SCANNING)

  const ndcFullInfo: NdcFullInfo = data?.getNdcFullInfo[0]
  const packageInfo = ndcFullInfo?.packages.find(pkg => pkg.ndc === fill.dispensed.ndc)
  const isUnbreakable = packageInfo?.isUnbreakable

  useInitTasks(fill, fill.dispensed.ndc)
  useSubmitOnComplete(tasks, fill, order, requireModalQuantityFilled, manualEntryFilled, stockInfo)

  // Open chrome devtools -> sources for a preview barcode
  // Must add localStorage item: devtoolBarcode=1
  // You can find examples of GS1 DataMatrix Barcodes in fakeBarcodeScanner.ts
  useDevToolBarcode(tasks.map(({ label, code }) => ({ label, code })))

  const fillLabelScanned = useMemo(() => !!tasks.find(({ key }) => key === 'scanFill')?.completed, [tasks])

  const handleShortcodeScan = useCallback(
    (value: string) => {
      if (fill.shortFillCode === value) {
        if (fillLabelScanned) {
          completeTask('finalScanFill')
        } else {
          completeTask('scanFill')
        }
      }
    },
    [completeTask, fill, fillLabelScanned],
  )

  const handleNDCScan = useCallback(
    (value: string) => {
      if (!allowLinearScan) return
      if (!fillLabelScanned) {
        showErrorToastRef.current('Please scan the Fill label before scanning a product')
        return
      }

      if (firstScanType === BarcodeTypes.twoDimensional) {
        showErrorToastRef.current('Please scan the same barcode type for every pack.')
        return
      }

      let scannedNdcIsAMatch: string | boolean | undefined = validateNdcBarcode(value, fill.dispensed.ndc)

      if (!scannedNdcIsAMatch && alternateBarCodes.length > 0) {
        scannedNdcIsAMatch = isScannedCodeAnAlternateBarCode(value, alternateBarCodes)
      }

      if (!scannedNdcIsAMatch) {
        showErrorToastRef.current('Incorrect stock product (GTIN) barcode scanned.')
        console.error('Incorrect stock product (GTIN) barcode scanned.')
        return
      }

      showSuccessToastRef.current('Scanned product: ' + value)
      completeTask('scanProduct-' + nextProductScanTaskIndex, fill.dispensed.ndc)
      setFirstScanType(BarcodeTypes.oneDimensional)
    },
    [
      fill,
      fillLabelScanned,
      nextProductScanTaskIndex,
      completeTask,
      showErrorToastRef,
      showSuccessToastRef,
      alternateBarCodes,
      allowLinearScan,
      firstScanType,
      setFirstScanType,
    ],
  )

  // This function will handle the logic for when the user scans a datamatrix barcode
  const handleGTINScan = useCallback(
    (parsedGS1: Record<string, string>) => {
      const parsedGTIN: string = parsedGS1['01']
      const parsedLot: string = parsedGS1['10']
      const parsedExpirationDate: string = parsedGS1['17']
      const parsedSerialNumber: string | null = parsedGS1['21'] || null
      if (!fillLabelScanned) {
        showErrorToastRef.current('Please scan the Fill label before scanning a product')
        return
      }

      if (firstScanType === BarcodeTypes.oneDimensional) {
        showErrorToastRef.current('Please scan the same barcode type for every pack.')
        return
      }
      // Check if package is unbreakable and all product scans are completed
      if (isUnbreakable && scanProductsTasksCompleted) {
        showErrorToastRef.current('All products have already been scanned.')
        console.error('All products have already been scanned.')
        return
      }
      // Check if GTIN matches to NDC
      let scannedGTINIsAMatch: string | boolean | undefined = validateNdcBarcode(parsedGTIN, fill.dispensed.ndc, true)

      if (!scannedGTINIsAMatch && alternateBarCodes.length > 0) {
        // Fallback alternate bar codes
        scannedGTINIsAMatch = isScannedCodeAnAlternateBarCode(parsedGTIN, alternateBarCodes)
      }
      if (!scannedGTINIsAMatch) {
        showErrorToastRef.current('Incorrect stock product (GTIN) barcode scanned.')
        console.error('Incorrect stock product (GTIN) barcode scanned.')
        return
      }

      // If the barcode we are scanning doesn't contain Lot AND ExpirationDate, then we are assuming it is NOT a Datamatrix
      if (!parsedLot && !parsedExpirationDate) {
        showErrorToastRef.current(
          'Please scan datamatrix barcode or escalate to lead. If datamatrix barcode does not exist, please manually enter data.',
        )
        console.error(
          'Lot and expiration date are not captured. This is not a datamatrix barcode and manual data entry is required.',
        )
        return
      }

      // We are preventing to scan a datamatrix barcode with missing requirements (not lot or not expiration date)
      if (!parsedLot || !parsedExpirationDate) {
        showErrorToastRef.current('Lot or expiration date not captured - escalate to lead.')
        console.error('Lot or expiration date not captured.')
        return
      }

      // We are preventing the use of miscalibrated scanners, where lot or serial number length is greater than 20
      if (parsedLot?.length > 20 || (parsedSerialNumber && parsedSerialNumber.length > 20)) {
        showErrorToastRef.current('Lot or Serial number length greater than 20. Please check scanner')
        console.error('Lot or Serial number length greater than 20. Please check scanner')
        return
      }

      const confirmScan = () => {
        // Format the expiration date to match manual entry
        const formattedExpirationDate = formatExpirationDate(parsedExpirationDate)

        const packageCount = fill.dispensed.packagesRequired || 1
        const quantity = fill.dispensed.quantity / packageCount

        // Save the data coming from the datamatrix barcode to the backend
        setStockInfo((prevStockInfo: StockInfo[]) => {
          // Check if the serial number already exists to prevent saving duplicate stockInfo in the backend JR-17745
          if (parsedSerialNumber && prevStockInfo.some(info => info.serialNumber === parsedSerialNumber)) {
            showErrorToastRef.current('Serial number already scanned.')
            console.error('Serial number already scanned.')
            return prevStockInfo
          } else {
            showSuccessToastRef.current('Scanned product: ' + parsedGTIN)
            const taskToComplete = tasks.find(task => task.code === fill.dispensed.ndc && !task.completed)
            if (taskToComplete) completeTask(`scanProduct-${nextProductScanTaskIndex}`, fill.dispensed.ndc)
            setFirstScanType(BarcodeTypes.twoDimensional)
            return [
              ...prevStockInfo,
              {
                lot: parsedLot,
                expirationDate: formattedExpirationDate,
                serialNumber: parsedSerialNumber,
                quantity: scanProductsTasksCompleted ? 0 : quantity,
              },
            ]
          }
        })
      }

      // Show modal for lot accuracy when scanned lot is not found in expectedLots
      if (enableLotAccuracyModal && !fill.dispensed.ffsInventoryInfo?.expectedLots?.includes(parsedLot)) {
        showModal(() => (
          <LotAccuracyFillModal
            lot={parsedLot}
            expectedLots={fill.dispensed.ffsInventoryInfo?.expectedLots || []}
            confirmationCallback={() => {
              confirmScan()
              createLog({
                variables: {
                  fillId: fill._id,
                  message: `Lot accuracy has been manually confirmed for ${parsedLot}`,
                  event: 'lot accuracy',
                },
              })
            }}
          />
        ))
      } else {
        confirmScan()
      }
    },
    [
      fill,
      fillLabelScanned,
      nextProductScanTaskIndex,
      completeTask,
      showErrorToastRef,
      showSuccessToastRef,
      showModal,
      createLog,
      setStockInfo,
      tasks,
      isUnbreakable,
      scanProductsTasksCompleted,
      alternateBarCodes,
      firstScanType,
      setFirstScanType,
      enableLotAccuracyModal,
    ],
  )
  //This checks information coming from a datamatrix barcode and it splits the string into different segments
  // GTIN, Expiration Date and Lot
  const handleGS1Scan = useCallback(
    (value: string) => {
      const parsedGS1 = parseGS1String(value)
      if (parsedGS1['01']) {
        handleGTINScan(parsedGS1)
      }
    },
    [handleGTINScan],
  )

  useEffect(() => {
    registerListener(REGEXES.ShortFillCode, handleShortcodeScan)
    registerListener(REGEXES.NDC, handleNDCScan)
    registerListener(REGEXES.GS1, handleGS1Scan)
    return () => {
      deregisterListener(REGEXES.ShortFillCode, handleShortcodeScan)
      deregisterListener(REGEXES.GS1, handleGS1Scan)
      deregisterListener(REGEXES.NDC, handleNDCScan)
    }
  }, [handleShortcodeScan, handleGTINScan, handleNDCScan, handleGS1Scan, deregisterListener, registerListener])

  const formattedTasks = useMemo(() => {
    return tasks.map(({ label, completed, subtasks }) => {
      return { label, completed, subtasks }
    })
  }, [tasks])

  return <TaskList tasks={formattedTasks} />
}

export default FillTaskList
