import { useCallback, useEffect } from 'react'
import { GridItem, Grid, Button, BannerAlert, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { UPDATE_SIG_CODE } from 'gql'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { ButtonContainer, SigCodeFormRaw, validateForm } from './SigCodeForm'

interface EditSigCodeModalProps {
  sigCodeId: string
  code: string
  enText: string
  esText?: string
}

const EditSigCodeModal = ({ code, enText, esText, sigCodeId }: EditSigCodeModalProps): JSX.Element => {
  return (
    <ModalWrapper
      styles={[
        {
          width: '31rem',
          /* TODO: change to this width when adding the edits: width: '61rem'*/
        },
      ]}
      id="editSigCodeModal"
    >
      <ModalHeader>
        <ModalTitle>Edit sig code</ModalTitle>
      </ModalHeader>
      <EditSigCodeModalContent code={code} enText={enText} esText={esText} sigCodeId={sigCodeId} />
    </ModalWrapper>
  )
}

const EditSigCodeModalContent = ({ code, enText, esText, sigCodeId }: EditSigCodeModalProps): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const sigCode = formData.sigCode?.code
  const englishTranslation = formData.sigCode?.translations?.en
  const spanishTranslation = formData.sigCode?.translations?.es
  const formIsValid = validateForm(formData)

  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const [updateSigCode, { data, loading, error }] = useMutation(UPDATE_SIG_CODE)

  useEffect(() => {
    updateFormData({
      sigCode: {
        $set: {
          code,
          translations: { es: esText, en: enText },
        },
      },
    })
  }, [code, enText, esText, updateFormData])

  useEffect(() => {
    if (data) {
      showSuccessToast('Sig code edited succesfully.')
      dismissModal()
    }
  }, [data, dismissModal, showSuccessToast])

  const handleSubmit = useCallback(() => {
    if (sigCode && englishTranslation) {
      updateSigCode({
        variables: {
          id: sigCodeId,
          patch: { code: sigCode, enTranslation: englishTranslation, esTranslation: spanishTranslation },
        },
      })
    }
  }, [englishTranslation, sigCode, spanishTranslation, updateSigCode, sigCodeId])

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return (
    <>
      {error && (
        <BannerAlert state="error">
          <Text variant="body-sm">{error.message}</Text>
        </BannerAlert>
      )}
      <Grid>
        <GridItem desktop={12} tablet={8}>
          <Title>Sig Code</Title>
          <SigCodeFormRaw />
        </GridItem>
        <GridItem desktop={6} tablet={4} css={{ display: 'none' }}>
          <Title>Edits</Title>
        </GridItem>
      </Grid>
      <ButtonContainer>
        <Button disabled={!formIsValid} onClick={handleSubmit} size="sm">
          Update
        </Button>
      </ButtonContainer>
    </>
  )
}

const Title = styled.h1`
  margin: 1rem 0;
`

const LoadingSpinnerContainer = styled.div`
  width: 10rem;
  margin: 0 auto;
`

export default EditSigCodeModal
