import { ProviderType } from '@truepill/tpos-types'
import Lozenge from 'components/Tiles/Lozenge'
import moment from 'moment'
import { primaryColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import { ListRowLabel, ListRowValue, RXDataRow } from './StyledComponents'

type DemographicBlockType = { prescriber: Prescriber; isVet: boolean }

const getGenderString = (prescriber: Prescriber) => {
  if (prescriber?.npiDoc?.gender === 'F') {
    return 'Female'
  } else if (prescriber?.npiDoc?.gender === 'M') {
    return 'Male'
  }
  return ''
}

const getSuffix = (prescriber: Prescriber) => {
  if (prescriber.suffix) {
    return `, ${prescriber.suffix}`
  } else if (prescriber?.npiDoc?.credential) {
    const credential: string = prescriber.npiDoc.credential
    const providerType: string = ProviderType[credential as keyof typeof ProviderType]
    return `, ${providerType ? providerType : prescriber.npiDoc.credential}`
  }
  return ''
}

const DemographicBlock = ({ prescriber, isVet }: DemographicBlockType): JSX.Element => {
  return (
    <>
      <RXDataRow>
        <ListRowLabel>Name:</ListRowLabel>
        <ListRowValue>
          {prescriber.firstName} {prescriber.lastName}
          {getSuffix(prescriber)}
        </ListRowValue>
      </RXDataRow>
      {isVet && prescriber.npi ? (
        <RXDataRow>
          <ListRowLabel>NPI:</ListRowLabel>
          <ListRowValue>
            <ul>
              <li>{prescriber.npi}</li>
              <li>
                Expiration date:{' '}
                {prescriber?.npiDoc?.deactivationDate
                  ? moment(prescriber?.npiDoc?.deactivationDate).format('MM/DD/YYYY')
                  : `N/A`}
              </li>
            </ul>
            <Lozenge backgroundColor={primaryColor}>ACTIVE</Lozenge>
          </ListRowValue>
        </RXDataRow>
      ) : (
        ''
      )}
      <RXDataRow>
        <ListRowLabel>{isVet ? 'License #:' : 'NPI:'}</ListRowLabel>
        {isVet ? (
          <ListRowValue>{prescriber.stateLicense}</ListRowValue>
        ) : (
          <ListRowValue>
            <ul>
              <li>{prescriber.npi}</li>
              <li>
                Expiration date:{' '}
                {prescriber?.npiDoc?.deactivationDate
                  ? moment(prescriber?.npiDoc?.deactivationDate).format('MM/DD/YYYY')
                  : `N/A`}
              </li>
            </ul>
            <Lozenge backgroundColor={primaryColor}>ACTIVE</Lozenge>
          </ListRowValue>
        )}
      </RXDataRow>
      {isVet ? (
        ''
      ) : (
        <RXDataRow>
          <ListRowLabel>Gender:</ListRowLabel>
          <ListRowValue>{getGenderString(prescriber)}</ListRowValue>
        </RXDataRow>
      )}
    </>
  )
}

export default DemographicBlock
