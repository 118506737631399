import { useState } from 'react'
import BreadCrumb from 'components/BreadCrumb'
import { PageContent, StickyPageHeadingContainer, PageHeading } from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  OrderButton,
  LogButton,
  NotesButton,
  MedicalHistoryButton,
  DataButton,
  ClaimSummaryButton,
  MonographButton,
  AttachmentsButton,
  EditReversalInfoButton,
} from 'components/ToolBar'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import styled from 'styled-components'
import type { TPOSDocument, Fill, Order, Patient, Prescription, Log, RXFillRequest } from 'types'
import { getFillLevelNotes, getFillParentNotes, getOrderAttachmentsCount, getParentAttachments } from 'utils'
import EditReversalInfoView from '../claims/editReversalInfoView'
import ActionButtons from './ActionButton'
import PrimaryTableSection from './TableSections/Primary'

type CompleteViewProps = {
  order: Order
  fill: Fill
  prescription: Prescription
  patient: Patient
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  orderLogs: Log[]
}
const CompleteView = ({
  fill,
  prescription,
  order,
  patient,
  rxFillRequest,
  docs,
  orderLogs,
}: CompleteViewProps): JSX.Element => {
  useCriticalNotesModal({ order, prescription, logs: orderLogs })
  const [displayEditReversalInfo, setDisplayEditReversalInfo] = useState(false)

  if (!order || !fill || !prescription || !patient) {
    return <></>
  }

  const allowClaimSummaryButton = (fill?.claims?.length ?? 0) > 0
  const failedClaimReversals = fill.claims.find(claim => claim?.status === 'Paid' && claim?.failedReversals?.length > 0)

  const parentNotes = getFillParentNotes(orderLogs, order._id)
  const fillLevelNotes = getFillLevelNotes(orderLogs, fill._id, order._id)

  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)

  if (displayEditReversalInfo) {
    if (!order || !rxFillRequest) {
      return <></>
    }
    return (
      <EditReversalInfoView
        item={order}
        itemFill={rxFillRequest}
        prescription={prescription}
        patient={patient}
        fill={fill}
        setDisplayEditReversalInfo={setDisplayEditReversalInfo}
      />
    )
  }

  return (
    <>
      <StickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        <StyledBreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          <ActionButtons fill={fill} order={order} />
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <PrimaryTableSection
                  order={order}
                  fill={fill}
                  patient={patient}
                  prescription={prescription}
                  rxFillRequest={rxFillRequest}
                  orderLogs={orderLogs}
                />
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          <LogButton logs={orderLogs ? uniqueLogEntries(orderLogs) : []} fillId={fill._id} />
          <NotesButton
            notesCount={fillLevelNotes.length}
            showBadge={parentNotes.length > 0 || fillLevelNotes.length > 0}
          />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patient._id} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
          {allowClaimSummaryButton && (
            <ClaimSummaryButton fill={fill} order={order} rxFillRequest={rxFillRequest} prescription={prescription} />
          )}
          {failedClaimReversals && <EditReversalInfoButton setDisplayEditReversalInfo={setDisplayEditReversalInfo} />}
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-top: 1.25rem;
`

export default CompleteView
