import { DEFAULT_LANGUAGE } from '@truepill/tpos-types'
import type { Spec } from 'immutability-helper'
import moment from 'moment'
import type { DeepPartial } from 'ts-essentials'
import type {
  TPOSDocument,
  Order,
  TPOSLocation,
  ClaimsRequest,
  Prescription,
  Fill,
  Patient,
  Printer,
  User,
  TransferRequestFormData,
  TransferOutFormData,
  RequestRefillFormData,
  TransferToPioneerFormData,
  Prescriber,
  PrescriptionViewConfig,
  Log,
  SigCode,
  InventoryGroup,
  Escript,
} from 'types'
import { DATE_FORMAT } from 'utils'

export type FormUpdate = Partial<{
  [key in keyof FormDataState]: Spec<FormDataState[key]>
}>

export interface FormDataState {
  fill: DeepPartial<Fill>
  order: DeepPartial<Order>
  location: DeepPartial<TPOSLocation>
  claims: DeepPartial<ClaimsRequest>
  patient: DeepPartial<Patient>
  prescriber: DeepPartial<Prescriber>
  prescription: DeepPartial<Prescription>
  printer: DeepPartial<Printer>
  user: DeepPartial<User>
  transferRequest: DeepPartial<TransferRequestFormData>
  transferOut: DeepPartial<TransferOutFormData>
  transferToPioneer: DeepPartial<TransferToPioneerFormData>
  refillRequest: DeepPartial<RequestRefillFormData>
  document: DeepPartial<TPOSDocument>
  note: DeepPartial<Log>
  sigCode: DeepPartial<SigCode>
  prescriptionView: PrescriptionViewConfig
  inventoryGroup: DeepPartial<InventoryGroup>
  escript: DeepPartial<Escript>
}

export const defaultState: FormDataState = {
  fill: {},
  order: {},
  location: {},
  claims: {},
  escript: {
    medicationPrescribed: {
      daysSupply: 0,
      quantity: {
        value: 0,
      },
      sig: '',
      note: '',
      drugDescription: '',
      drugCoded: {
        productCode: {
          code: '',
        },
      },
    },
  },
  patient: {
    firstName: '',
    lastName: '',
    address: {
      home: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
    contacts: {
      phone: '',
      email: '',
      sms: '',
    },
    medicalHistory: {
      allergies: [],
      conditions: [],
      medications: [],
      custom: {
        allergies: [],
        conditions: [],
        medications: [],
      },
    },
    dob: moment().format(DATE_FORMAT),
    preferences: {
      language: DEFAULT_LANGUAGE,
    },
  },
  prescriber: {},
  prescription: {
    icd10: [],
    icd10s: [],
    writtenDate: moment().format(DATE_FORMAT),
    expirationDate: moment().add(1, 'y').format(DATE_FORMAT),
    daw: 0,
  },
  prescriptionView: {
    rxHasNotBeenFilled: false,
    pharmacy: {},
  },
  printer: {},
  user: {},
  transferRequest: {},
  transferOut: {},
  transferToPioneer: {},
  refillRequest: {},
  document: {},
  note: {
    message: '',
    tags: [],
  },
  sigCode: {
    code: '',
    translations: {
      en: '',
      es: '',
    },
  },
  inventoryGroup: {
    customerId: '',
    pioneerAlias: '',
    active: false,
  },
}
