import { generatePath } from '@truepill/tpos-react-router'
import type { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { TaskQueueName } from 'hooks/useTaskQueue'

export enum RoutePath {
  Login = '/login',
  ResetPassword = '/resetPassword',
  FulfillmentFill = '/fulfillment/:fulfillmentQueueName/order/:orderId/fill/:fillId',
  FulfillmentOrder = '/fulfillment/:fulfillmentQueueName/order/:orderId',
  FulfillmentCopay = '/fulfillment/:fulfillmentQueueName/copay/:copayRequestId',
  FulfillmentCopayViaToken = '/fulfillment/:fulfillmentQueueName/copay-token/:coreCopayToken',
  FulfillmentCopayRequestFill = '/fulfillment/:fulfillmentQueueName/copay/:copayRequestId/fill/:fillId',
  FulfillmentPriorAuthorization = '/fulfillment/prior-auth/:priorAuthorizationId',
  ScannerTest = '/fulfillment/scannertest',
  Fulfillment = '/fulfillment/:fulfillmentQueueName?',
  OutboundTransfer = '/tasks/outboundTransfer/:outboundTransferId',
  Tasks = '/tasks/:taskQueueName',
  ViewPrescriber = '/people/prescribers/:npi',
  ViewPrescriberById = '/people/prescribers/:prescriberId',
  AddPrescriber = '/people/prescribers/addPrescriber',
  AddPrescriberPractice = '/people/prescribers/:npi/addPractice',
  AddPrescriberPracticeById = '/people/prescribers/:prescriberId/addPractice',
  CreatePatient = '/people/patients/create',
  EditPatient = '/people/patients/:patientId/edit',
  ViewPatient = '/people/patients/:patientId',
  ViewPatients = '/people/patients',
  Team = '/people/truepillTeam',
  MyProfile = '/people/me',
  UserProfile = '/people/:userId',
  EditUser = '/users/:userId/edit',
  ViewUserOrderDetails = '/users/:userId/orders/:orderId',
  ViewUserOrders = '/users/:userId/orders',
  Prescribers = '/people/prescribers',
  CreatePayer = '/people/payers/create',
  EditPayer = '/people/payers/:payerId/edit',
  ViewPayer = '/people/payers/:payerId',
  ViewPayers = '/people/payers',
  People = '/people',
  CreatePharmacyPrescriptionForPatient = '/pharmacy/prescriptions/create/:patientId',
  CreatePharmacyPrescriptionForPatientV2 = '/pharmacy/prescriptions/create-v2/:patientId',
  CreatePharmacyPrescription = '/pharmacy/prescriptions/create',
  CreatePharmacyPrescriptionV2 = '/pharmacy/prescriptions/create-v2',
  EditPharmacyPrescription = '/pharmacy/prescriptions/:prescriptionId/edit',
  ViewRxImagePharmacyPrescription = '/pharmacy/prescriptions/:prescriptionId/image',
  ViewPharmacyPrescription = '/pharmacy/prescriptions/:prescriptionId',
  ViewPharmacyPrescriptions = '/pharmacy/prescriptions',
  ViewDrugDetails = '/pharmacy/drugs/:ndc',
  ViewOutboundScan = '/outboundScan',
  ViewPharmacyEscripts = '/pharmacy/e-scripts',
  ViewPharmacyEscript = '/pharmacy/e-scripts/:escriptId',
  ViewPharmacyCopay = '/pharmacy/copays/:copayRequestId',
  ViewPharmacyCopayByCopayToken = '/pharmacy/copay/copay-token/:coreCopayToken',
  ViewPharmacyCopays = '/pharmacy/copays',
  CreatePharmacyOrder = '/pharmacy/orders/create',
  ViewPharmacyOrder = '/pharmacy/orders/:orderId',
  ViewPharmacyOrderByCoreOrderId = '/pharmacy/orders/order-legacy-id/:coreOrderId',
  ViewPharmacyOrderFillRequestId = '/pharmacy/orders/fill-request/:coreFillRequestToken',
  ViewPharmacyOrderOrderToken = '/pharmacy/orders/order-token/:coreOrderToken',
  ViewPharmacyOrders = '/pharmacy/orders',
  ViewPharmacyTransfers = '/pharmacy/transfers',
  ViewPharmacyTransfer = '/pharmacy/transfers/:externalTransferId',
  ViewPharmacyDrugs = '/pharmacy/drugs',
  ViewPharmacyLocation = '/pharmacy/locations/:locationId',
  ViewPharmacyLocations = '/pharmacy/locations',
  ViewPharmacyAutomation = '/pharmacy/automation',
  ViewRxIntakeIssues = '/pharmacy/rxIntakeIssues',
  ViewRxIntakeDetails = '/pharmacy/rxIntakeIssues/:escriptId',
  Pharmacy = '/pharmacy',
  Admin = '/admin',
  AdminSigCodes = '/admin/sigCodes',
  AdminInventoryGroup = '/admin/inventoryGroup',
  Root = '/',
}

type escriptIdType = { escriptId: string }
type externalTransferIdType = { externalTransferId: string }
type fillIdType = { fillId: string }
type fulfillmentQueueNameAllType = { fulfillmentQueueName?: FulfillmentQueueName | 'all' }
type locationIdType = { locationId: string }
type ndcType = { ndc?: string }
type prescriberIdType = { prescriberId: string }
type orderIdType = { orderId: string }
type copayRequestIdType = { copayRequestId: string }
type patientIdType = { patientId: string }
type payerIdType = { payerId: string }
type prescriptionIdType = { prescriptionId: string }
type priorAuthorizationIdType = { priorAuthorizationId: string }
type searchType = { search?: string }
type showConfigType = { showConfigurations?: boolean }
type taskQueueNameType = { taskQueueName: TaskQueueName }
type userIdType = { userId?: string }
type outboundTransferIdType = { outboundTransferId: string }
type escriptSearchType = escriptIdType & searchType
type externalTransferSearchType = externalTransferIdType & searchType
type locationSearchType = locationIdType & searchType
type prescriberIdSearchType = prescriberIdType & searchType
type copaySearchType = copayRequestIdType & searchType
type orderSearchType = orderIdType & searchType
type patientIdSearchType = patientIdType & searchType
type patientSearchType = patientIdType & searchType
type payerConfigType = payerIdType & showConfigType
type payerSearchType = payerIdType & searchType
type prescriptionSearchType = prescriptionIdType & searchType
type taskQueueSearchType = taskQueueNameType & searchType
type userOrderType = userIdType & orderIdType
type outboundTransferType = outboundTransferIdType & searchType

type fulfillmentType = fulfillmentQueueNameAllType & searchType
type fulfillmentOrderType = fulfillmentType & orderIdType
type fulfillmentCopayType = fulfillmentType & copayRequestIdType
type fulfillmentFillType = fulfillmentOrderType & fillIdType
type fulfillmentCopayRequestFillType = fulfillmentCopayType & fillIdType
type fulfillmentPriorAuthorizationType = priorAuthorizationIdType

export const goToLogin = ({ search = '' }: searchType): string => RoutePath.Login + search

export const goToResetPassword = ({ search = '' }: searchType): string => RoutePath.ResetPassword + search

export const gotoSigAdminCodes = (): string => RoutePath.AdminSigCodes
export const gotoInventoryGroups = (): string => RoutePath.AdminInventoryGroup

export const goToFulfillmentFill = ({
  fillId,
  fulfillmentQueueName = 'all',
  orderId,
  search = '',
}: fulfillmentFillType): string =>
  generatePath(RoutePath.FulfillmentFill, { fulfillmentQueueName: fulfillmentQueueName, orderId, fillId }) + search

export const goToFulfillmentOrder = ({
  fulfillmentQueueName = 'all',
  orderId,
  search = '',
}: fulfillmentOrderType): string => generatePath(RoutePath.FulfillmentOrder, { fulfillmentQueueName, orderId }) + search

export const goToFulfillmentCopayRequestFill = ({
  fulfillmentQueueName = 'all',
  copayRequestId,
  fillId,
  search = '',
}: fulfillmentCopayRequestFillType): string =>
  generatePath(RoutePath.FulfillmentCopayRequestFill, { fulfillmentQueueName, copayRequestId, fillId }) + search

export const goToFulfillmentCopay = ({
  fulfillmentQueueName = 'all',
  copayRequestId,
  search = '',
}: fulfillmentCopayType): string =>
  generatePath(RoutePath.FulfillmentCopay, { fulfillmentQueueName, copayRequestId }) + search

export const goToScannerTest = ({ search = '' }: searchType): string => RoutePath.ScannerTest + search

export const goToFulfillment = ({ fulfillmentQueueName, search }: fulfillmentType): string => {
  const exludeParams = ['page', 'hasNoPrints']

  const cleanSearch = (searchString?: string) =>
    searchString
      ?.substring(1)
      ?.split('&')
      .filter(searchParam => !exludeParams.includes(searchParam.split('=')[0]))
      .join('&')

  return generatePath(RoutePath.Fulfillment, { fulfillmentQueueName }) + `?${cleanSearch(search)}`
}

export const goToTaskQueue = ({
  taskQueueName = TaskQueueName.PatientMatch,
  search = '',
}: taskQueueSearchType): string => `${generatePath(RoutePath.Tasks, { taskQueueName })}${search}`

export const goToCreatePatient = ({ search = '' }: searchType): string => RoutePath.CreatePatient + search

export const goToEditPatient = ({ patientId, search = '' }: patientIdSearchType): string =>
  generatePath(RoutePath.EditPatient, { patientId }) + search

export const goToViewPatient = ({ patientId, search = '' }: patientIdSearchType): string =>
  generatePath(RoutePath.ViewPatient, { patientId }) + search

export const goToViewPatients = ({ search = '' }: searchType): string => RoutePath.ViewPatients + search

export const goToEditUser = ({ userId = '' }: userIdType): string => generatePath(RoutePath.EditUser, { userId })

export const goToUserOrderDetails = ({ userId = '', orderId = '' }: userOrderType): string =>
  generatePath(RoutePath.ViewUserOrderDetails, { userId, orderId })

export const goToUserOrders = ({ userId = '' }: userIdType): string =>
  generatePath(RoutePath.ViewUserOrders, { userId })

export const goToTeam = ({ search = '' }: searchType): string => RoutePath.Team + search

export const goToMyProfile = (): string => RoutePath.MyProfile

export const goToPrescribers = ({ search = '' }: searchType): string => RoutePath.Prescribers + search

export const goToPayers = ({ search = '' }: searchType): string => RoutePath.ViewPayers + search

export const goToViewPayer = ({ payerId, search = '' }: payerSearchType): string =>
  generatePath(RoutePath.ViewPayer, { payerId }) + search

export const goToEditPayer = ({ payerId, showConfigurations = false }: payerConfigType): string =>
  generatePath(RoutePath.EditPayer, { payerId }) + `?showConfigurations=${showConfigurations}`

export const goToCreatePayer = ({ search = '' }: searchType): string => RoutePath.CreatePayer + search

export const goToViewPrescriber = ({ prescriberId, search = '' }: prescriberIdSearchType): string =>
  generatePath(RoutePath.ViewPrescriberById, { prescriberId }) + search

export const goToAddPrescriber = ({ search = '' }: searchType): string => generatePath(RoutePath.AddPrescriber) + search

export const goToAddPrescriberPractice = ({ prescriberId, search = '' }: prescriberIdSearchType): string =>
  generatePath(RoutePath.AddPrescriberPracticeById, { prescriberId }) + search

export const goToPeople = ({ search = '' }: searchType): string => RoutePath.People + search

export const goToUserProfile = ({ userId = '' }: userIdType): string => generatePath(RoutePath.UserProfile, { userId })

export const goToCreatePharmacyPrescriptionForPatient = ({ patientId, search = '' }: patientSearchType): string =>
  generatePath(RoutePath.CreatePharmacyPrescriptionForPatient, { patientId }) + search

export const goToCreatePharmacyPrescriptionForPatientV2 = ({ patientId, search = '' }: patientSearchType): string =>
  generatePath(RoutePath.CreatePharmacyPrescriptionForPatientV2, { patientId }) + search

export const goToCreatePharmacyPrescription = ({ search = '' }: searchType): string =>
  RoutePath.CreatePharmacyPrescription + search

export const goToCreatePharmacyPrescriptionV2 = ({ search = '' }: searchType): string =>
  RoutePath.CreatePharmacyPrescriptionV2 + search

export const goToEditPharmacyPrescription = ({ prescriptionId, search = '' }: prescriptionSearchType): string =>
  generatePath(RoutePath.EditPharmacyPrescription, { prescriptionId }) + search

export const goToViewRxImagePharmacyPrescription = ({ prescriptionId, search = '' }: prescriptionSearchType): string =>
  generatePath(RoutePath.ViewRxImagePharmacyPrescription, { prescriptionId }) + search

export const goToViewPharmacyPrescription = ({ prescriptionId, search = '' }: prescriptionSearchType): string =>
  generatePath(RoutePath.ViewPharmacyPrescription, { prescriptionId }) + search

export const goToViewPharmacyEscript = ({ escriptId, search = '' }: escriptSearchType): string =>
  generatePath(RoutePath.ViewPharmacyEscript, { escriptId }) + search

export const goToViewPharmacyPrescriptions = ({ search = '' }: searchType): string =>
  RoutePath.ViewPharmacyPrescriptions + search

export const goToViewPharmacyEscripts = ({ search = '' }: searchType): string => RoutePath.ViewPharmacyEscripts + search

export const goToViewPharmacyCopay = ({ copayRequestId, search = '' }: copaySearchType): string =>
  generatePath(RoutePath.ViewPharmacyCopay, { copayRequestId }) + search

export const goToViewPharmacyCopays = ({ search = '' }: searchType): string => RoutePath.ViewPharmacyCopays + search

export const goToCreatePharmacyOrder = ({ search = '' }: searchType): string => RoutePath.CreatePharmacyOrder + search

export const goToViewPharmacyOrder = ({ orderId, search = '' }: orderSearchType): string =>
  generatePath(RoutePath.ViewPharmacyOrder, { orderId }) + search

export const goToViewPharmacyTransfers = ({ search = '' }: searchType): string =>
  RoutePath.ViewPharmacyTransfers + search

export const goToViewPharmacyDrugs = ({ search = '' }: searchType): string => RoutePath.ViewPharmacyDrugs + search

export const goToViewPharmacyLocation = ({ locationId, search = '' }: locationSearchType): string =>
  generatePath(RoutePath.ViewPharmacyLocation, { locationId }) + search

export const goToViewPharmacyLocations = ({ search = '' }: searchType): string =>
  RoutePath.ViewPharmacyLocations + search

export const goToViewPharmacyAutomation = ({ search = '' }: searchType): string =>
  RoutePath.ViewPharmacyAutomation + search

export const goToPharmacy = ({ search = '' }: searchType): string => RoutePath.Pharmacy + search

export const goToRoot = ({ search = '' }: searchType): string => RoutePath.Root + search

export const goToViewDrugDetails = ({ ndc = '' }: ndcType): string => generatePath(RoutePath.ViewDrugDetails, { ndc })

export const goToViewRxIntakeIssues = ({ search = '' }: searchType): string => RoutePath.ViewRxIntakeIssues + search

export const goToViewRxIntakeDetails = ({ escriptId, search = '' }: escriptSearchType): string =>
  generatePath(RoutePath.ViewRxIntakeDetails, { escriptId })

export const goToOutboundTransfer = ({ outboundTransferId, search = '' }: outboundTransferType): string =>
  generatePath(RoutePath.OutboundTransfer, { outboundTransferId }) + search

export const goToPriorAuthorization = ({ priorAuthorizationId }: fulfillmentPriorAuthorizationType): string =>
  generatePath(RoutePath.FulfillmentPriorAuthorization, { priorAuthorizationId })

export const goToOutboundScan = ({ search = '' }: searchType): string => RoutePath.ViewOutboundScan + search

export const goToViewPharmacyExternalTransfer = ({
  externalTransferId,
  search = '',
}: externalTransferSearchType): string => generatePath(RoutePath.ViewPharmacyTransfer, { externalTransferId }) + search
