import { useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import { GET_FULL_ORDER } from 'gql'
import { useFormData } from 'hooks/useFormData'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { GuestPatient, Order, Patient, RXFillRequest, Log } from 'types'
import { updateOrder, updatePrescriptionOnOrder } from 'utils/subscribeToolkit'
import type { LoadingError } from './types'
import usePatient from './usePatient'

export interface BasicOrder extends LoadingError {
  order?: Order
  notes?: Log[]
  patient?: Patient
  guestPatient?: GuestPatient
  apiRequest: any
}

export interface OrderFormValues {
  durInputs: Order['durInputs']
}

const OrderDefaults: BasicOrder = {
  order: undefined,
  apiRequest: {},
}

/**
 * @param orderId If undefined, an undefined order will be returned. This is to
 *                allow for use with components that have an order in some
 *                cases but not others (like `ReviewPrescription`).
 */
export default function useOrder(orderId?: string): BasicOrder {
  const { getCustomerById, getLocationById } = useTPCacheContext()
  const subscriptionsSkipIdTracker = useFlag(LaunchDarkly.FeatureFlags.SUBSCRIPTIONS_SKIP_ID_TRACKER)

  const fullOrder = useQuery(GET_FULL_ORDER, {
    skip: !orderId,
    variables: { orderId },
  })

  const { loading, error, data, subscribeToMore } = fullOrder

  const patientHookProps = usePatient({
    loading,
    error,
    patient: data?.getOrder?.patient,
    guestPatient: data?.getOrder?.guestPatient,
  })

  const {
    actions: { updateFormData },
  } = useFormData()

  useEffect(() => {
    if (orderId) {
      return updateOrder(subscribeToMore, { orderId }, subscriptionsSkipIdTracker)
    }
  }, [subscribeToMore, orderId, subscriptionsSkipIdTracker])

  useEffect(() => {
    if (data?.getOrder?.rxFillRequests?.length && subscribeToMore) {
      const prescriptionIds = data.getOrder.rxFillRequests.map((fr: RXFillRequest) => fr.prescriptionId)

      return updatePrescriptionOnOrder(subscribeToMore, { prescriptionIds }, false, subscriptionsSkipIdTracker)
    }
  }, [subscribeToMore, data?.getOrder, subscriptionsSkipIdTracker])

  // Init editable versions of the order data
  useEffect(() => {
    if (data?.getOrder?._id) {
      updateFormData({ order: { $set: data.getOrder } })
    }
  }, [data, updateFormData])

  if (loading || error || patientHookProps.loading || patientHookProps.error)
    return {
      loading: loading || patientHookProps.loading,
      error: error || patientHookProps.error,
    } as BasicOrder

  let value: BasicOrder = { ...OrderDefaults }

  if (orderId) {
    const o = data.getOrder as Order
    const order = Object.freeze({
      ...o,
      customer: getCustomerById(o.customerId),
      location: getLocationById(o.locationId),
    })

    const { patient, guestPatient, apiRequest } = order

    value = Object.assign({}, value, {
      loading,
      error,
      order: Object.freeze(order),
      apiRequest: apiRequest ? Object.freeze(JSON.parse(apiRequest as unknown as string)) : undefined,
      orderStatus: order.status,
      ...(patient && { patient }),
      ...(guestPatient && { guestPatient }),
    })
  }

  return value
}
